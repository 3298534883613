import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMobileScreenButton, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import '@fortawesome/fontawesome-free/css/all.css';
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import base64 from 'base-64';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import NewPage from './NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SyncLoader } from "react-spinners";
import Message from './common/message';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Profile() {
    const [btn, setBtn] = useState(0)
    const [reload, setReload] = useState(false)
    const [adminData, setAdminData] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());
    const [csData, setCsData] = useState([])
    const [csList, setCsList] = useState([])
    const [page, setPage] = useState(1)
    const [totalpage, setTotalpage] = useState(1)
    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState({ code: -1, msg: "" })

    let dark = useSelector((state) => { return state.Darkmode.value });
    let ex_name = useSelector((state) => { return state.ex_name.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        AdminData()
    }, [reload])

    const AdminData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/managers', {
                params: {
                    id: JSON.parse(dec).id,
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setAdminData(tmp.admin_members[0])
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onSendCertify = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/email/send', {
                params: {
                    email: JSON.parse(dec).sub
                }
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code === 1) {
                    setMessage({ code: 0, msg: "Send Verification Code" })
                }
                else {
                    setMessage({ code: 1, msg: "Failed Verification Code" })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        if (adminData?.id) CSData()
    }, [adminData])

    const CSData = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/manager/logs', {
                params: {
                    id: adminData.id
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data.data
                if (tmp) {
                    setCsData(tmp.admin_logs)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        onSpinner()
        setPage(1)
        let arr = []
        if (csData) {
            for (let i = 0; i < csData.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(csData[i].start_date_time).getTime() && new Date(csData[i].end_date_time).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(csData[i])
                }
            }
            setCsList(arr)
        }
    }, [csData, selectedDate, selectedDate1])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        let name = ""

        if (csData) {
            for (let i = 0; i < csData.length; i++) {
                let t = csData[i]

                let tmpdate = t.date_time
                let formattedDate = `${tmpdate[0]}.${padZero(tmpdate[1])}.${padZero(tmpdate[2])} ${padZero(tmpdate[3])}:${padZero(tmpdate[4])}:${tmpdate[5] ? padZero(tmpdate[5]) : "00"}`;

                name = t.manager
                arr.push({
                    "Time": formattedDate,
                    "Employee": t.manager,
                    "ActivityLog": t.log,
                    "RFREVID": t.target_uid,
                })
            }
        }

        Excel(arr, name, ex_name)
    }

    let slice_data = csList.slice((page - 1) * 10, (page) * 10)

    return (
        <div className='main'>
            <Message message={message} />
            <Sidebar page={14} />
            <div className={`main-box ${darkname}`}>
                <div className='profile-1'>
                    <div className='profile-1-1'>Update Personal Information</div>
                    <div className={`profile-1-2 ${darkname}`}>
                        <div className='profile-1-2-1'>
                            {/* <img src='/img/lock.png' /> */}
                            <FontAwesomeIcon icon={faEnvelope} />
                            <div>Modify Name</div>
                        </div>
                        <div className='profile-1-2-2'>
                            <div className={`profile-1-2-2-btn ${darkname}`} onClick={() => setBtn(1)}>Modify</div>
                        </div>
                    </div>
                    <div className={`profile-1-2 ${darkname}`}>
                        <div className='profile-1-2-1'>
                            <FontAwesomeIcon icon={faMobileScreenButton} />
                            <div>Modify Mobile</div>
                        </div>
                        <div className='profile-1-2-2'>
                            <div className={`profile-1-2-2-btn ${darkname}`} onClick={() => setBtn(2)}>Modify</div>
                        </div>
                    </div>
                    <div className={`profile-1-2 ${darkname}`}>
                        <div className='profile-1-2-1'>
                            {/* <img src='/img/lock.png' /> */}
                            <FontAwesomeIcon icon={faLock} />
                            <div>Modify Password</div>
                        </div>
                        <div className='profile-1-2-2'>
                            <div className={`profile-1-2-2-btn ${darkname}`} onClick={() => setBtn(3)}>Modify</div>
                        </div>
                    </div>
                </div>
                {
                    btn === 0
                        ?
                        ""
                        :
                        btn === 1
                            ?
                            <ModalName setBtn={setBtn} darkname={darkname} setReload={setReload} adminData={adminData} onSendCertify={onSendCertify} setMessage={setMessage} />
                            :
                            btn === 2
                                ?
                                <ModalMobile setBtn={setBtn} darkname={darkname} setReload={setReload} adminData={adminData} onSendCertify={onSendCertify} setMessage={setMessage} />
                                :
                                <ModalPassword setBtn={setBtn} darkname={darkname} setReload={setReload} adminData={adminData} onSendCertify={onSendCertify} setMessage={setMessage} />
                }
                <div className={`withdraw-1 ${darkname}`} style={{ marginTop: "80px" }}>
                    <div style={{ width: "85px", fontWeight: "bold" }}>CS Activity</div>
                    <div className={`modal-3-1-left ${darkname}`}>
                        <div className="modal-3-1-left-1 flex">
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                            />
                            <div>~</div>
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate1}
                                onChange={(date) => setSelectedDate1(date)}
                            />
                        </div>
                    </div>
                </div>
                <div className='cslog-1'>
                    <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
                </div>
                <div className='withdraw-3'>
                    <div className={`table-head ${darkname}`}>
                        <div>Time</div>
                        <div>Employee</div>
                        <div>Activity log</div>
                        <div>{ex_name} ID</div>
                    </div>
                    <div className='table-body' style={{ minHeight: "600px" }}>
                        {
                            spinner
                                ?
                                <div className='spinner-modal'>
                                    <SyncLoader color="#F5AC1D" />
                                </div>
                                :
                                slice_data && slice_data.length === 0
                                    ?
                                    <div className='data-nofound'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>NO RECORDS FOUND.</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            slice_data.map(function (a, i) {
                                                return (
                                                    <WithdrawItem key={i} item={a} darkname={darkname} />
                                                )
                                            })
                                        }
                                        <NewPage initialData={csList} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


function WithdrawItem({ item, darkname }) {
    let tmpdate = item.start_date_time.split("T")
    let tmpdate1 = tmpdate[1].split(".000Z")
    let formattedDate = `${tmpdate[0]} ${tmpdate1[0]}`;

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{formattedDate}</div>
            <div className='td'>{item.manager}</div>
            <div className='td'>{item.action}</div>
            <div className='td'>{item.target_uid}</div>
        </div>
    )
}

function ModalName({ setBtn, darkname, setReload, adminData, onSendCertify, setMessage }) {
    const [newname, setNewname] = useState("")
    const [code, setCode] = useState("")

    const onModifyNewName = (e) => {
        setNewname(e.target.value)
    }
    const onModifyCode = (e) => {
        setCode(e.target.value)
    }

    const onSubmitCertify = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/email/certify', {
                params: {
                    email: JSON.parse(dec).sub,
                    authCode: code
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response.data.code > 0) {
                    onSubmitName()
                }
                else {
                    setMessage({ code: 1, msg: "Invalid authentication number." })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onSubmitName = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/manager/name', {
                params: {
                    id: adminData.id,
                    name: newname
                }
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code === 1) {
                    setReload(e => !e)
                    setBtn(0)
                    setMessage({ code: 0, msg: "Name change succeeded" })
                }
                else {
                    setMessage({ code: 1, msg: "Name change failed" })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setBtn(0);
        }
    };

    document.addEventListener('keydown', handleEsc);


    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className={`modal-4 ${darkname}`}>
                    <div className={`modal-1-tit ${darkname}`}>Modify Name</div>
                    <div className='password'>
                        <div className={`password-1 ${darkname}`}>
                            <div>{adminData.name}</div>
                        </div>
                        <div className={`password-1 ${darkname}`}>
                            <input value={newname} onChange={onModifyNewName} placeholder='New Name' />
                        </div>
                        <div className={`password-1 ${darkname}`}>
                            <input value={code} onChange={onModifyCode} maxLength={6} placeholder='Verification Code' />
                            <div className={`verification-btn ${darkname}`} onClick={() => onSendCertify()}>Send</div>
                        </div>
                    </div>
                    <div className='password-2'>
                        <div className={`password-2-1 ${darkname}`} onClick={() => onSubmitCertify()}>Confirm</div>
                        <div className={`password-2-1 ${darkname}`} onClick={() => setBtn(0)}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ModalMobile({ setBtn, darkname, setReload, adminData, onSendCertify, setMessage }) {
    const [newmobile, setNewmobile] = useState("")
    const [code, setCode] = useState("")

    const onModifyNewMobile = (e) => {
        setNewmobile(e.target.value)
    }
    const onModifyCode = (e) => {
        setCode(e.target.value)
    }

    const onSubmitCertify = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/email/certify', {
                params: {
                    email: JSON.parse(dec).sub,
                    authCode: code
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response.data.code > 0) {
                    onSubmitMobile()
                }
                else {
                    setMessage({ code: 1, msg: "Invalid authentication number." })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onSubmitMobile = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/manager/mobile', {
                params: {
                    id: adminData.id,
                    mobile: newmobile
                }
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code === 1) {
                    setReload(e => !e)
                    setBtn(0)
                    setMessage({ code: 0, msg: "Mobile change succeeded" })
                }
                else {
                    setMessage({ code: 1, msg: "Mobile change failed" })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setBtn(0);
        }
    };

    document.addEventListener('keydown', handleEsc);

    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className={`modal-4 ${darkname}`}>
                    <div className={`modal-1-tit ${darkname}`}>Modify Mobile</div>
                    <div className='password'>
                        <div className={`password-1 ${darkname}`}>
                            <div>{adminData.mobile}</div>
                        </div>
                        <div className={`password-1 ${darkname}`}>
                            <input value={newmobile} onChange={onModifyNewMobile} placeholder='New Mobile' />
                        </div>
                        <div className={`password-1 ${darkname}`}>
                            <input value={code} onChange={onModifyCode} maxLength={6} placeholder='Verification Code' />
                            <div className={`verification-btn ${darkname}`} onClick={() => onSendCertify()}>Send</div>
                        </div>
                    </div>
                    <div className='password-2'>
                        <div className={`password-2-1 ${darkname}`} onClick={() => onSubmitCertify()}>Confirm</div>
                        <div className={`password-2-1 ${darkname}`} onClick={() => setBtn(0)}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ModalPassword({ setBtn, darkname, setReload, adminData, onSendCertify, setMessage }) {
    const [password, setPassword] = useState("")
    const [newpassword, setNewpassword] = useState("")
    const [code, setCode] = useState("")
    const [eye, setEye] = useState(false);
    const [eye1, setEye1] = useState(false);

    const onModifyPassword = (e) => {
        setPassword(e.target.value)
    }
    const onModifyNewPassword = (e) => {
        setNewpassword(e.target.value)
    }
    const onModifyCode = (e) => {
        setCode(e.target.value)
    }


    const onSubmitCertify = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/email/certify', {
                params: {
                    email: JSON.parse(dec).sub,
                    authCode: code
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response.data.code > 0) {
                    onSubmitPassword()
                }
                else {
                    setMessage({ code: 1, msg: "Invalid authentication number." })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const onSubmitPassword = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.get(process.env.REACT_APP_API + '/api/v1/admin/manager/password', {
                params: {
                    id: adminData.id,
                    authCode: code,
                    oldPw: password,
                    newPw: newpassword
                }
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                console.log(response.data)
                if (response?.data?.code > 0) {
                    setReload(e => !e)
                    setBtn(0)
                    setMessage({ code: 0, msg: "Password change succeeded" })
                }
                else {
                    setMessage({ code: 1, msg: "Password change failed" })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setBtn(0);
        }
    };

    document.addEventListener('keydown', handleEsc);

    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className={`modal-4 ${darkname}`}>
                    <div className={`modal-1-tit ${darkname}`}>Modify Password</div>
                    <div className='password'>
                        <div className={`password-1 ${darkname}`}>
                            <input type={eye ? 'text' : 'password'} value={password} onChange={onModifyPassword} placeholder='Current Password' />
                            {
                                eye
                                    ?
                                    <FontAwesomeIcon icon={faEye} className='password-eye' onClick={() => setEye(eye => !eye)} />
                                    :
                                    <FontAwesomeIcon icon={faEyeSlash} className='password-eye' onClick={() => setEye(eye => !eye)} />
                            }
                        </div>
                        <div className={`password-1 ${darkname}`}>
                            <input type={eye1 ? 'text' : 'password'} value={newpassword} onChange={onModifyNewPassword} placeholder='New Password' />
                            {
                                eye1
                                    ?
                                    <FontAwesomeIcon icon={faEye} className='password-eye' onClick={() => setEye1(eye => !eye)} />
                                    :
                                    <FontAwesomeIcon icon={faEyeSlash} className='password-eye' onClick={() => setEye1(eye => !eye)} />
                            }
                        </div>
                        <div className={`password-1 ${darkname}`}>
                            <input value={code} onChange={onModifyCode} maxLength={6} placeholder='Verification Code' />
                            <div className={`verification-btn ${darkname}`} onClick={() => onSendCertify()}>Send</div>
                        </div>
                    </div>
                    <div className='password-2'>
                        <div className={`password-2-1 ${darkname}`} onClick={() => onSubmitCertify()}>Confirm</div>
                        <div className={`password-2-1 ${darkname}`} onClick={() => setBtn(0)}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

const Excel = (excelData, name, ex_name) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `CS Log_${name}`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Time", "Employee", "ActivityLog", `${ex_name} ID`]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Time },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Employee },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.ActivityLog },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.RFREVID }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] =
            [
                { wpx: 130 },
                { wpx: 100 },
                { wpx: 100 },
                { wpx: 100 }
            ]

        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Profile;