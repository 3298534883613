let country = [
    { value: "82", code: "KR", name: "South Korea" },
    { value: "81", code: "JP", name: "Japan" },
    { value: "1", code: "US", name: "USA" },
    { value: "86", code: "CN", name: "China" },
    { value: "7", code: "RU", name: "Russia" },
    { value: "380", code: "UA", name: "Ukraine" },
    { value: "49", code: "DE", name: "Germany" },
    { value: "65", code: "SG", name: "Singapore" },
    { value: "84", code: "VN", name: "Vietnam" },
    { value: "7940", code: "AB", name: "Abkhazia" },
    { value: "7840", code: "AB", name: "Abkhazia" },
    { value: "93", code: "AF", name: "Afghanistan" },
    { value: "355", code: "AL", name: "Albania" },
    { value: "213", code: "DZ", name: "Algeria" },
    { value: "1684", code: "AS", name: "American Samoa" },
    { value: "376", code: "AD", name: "Andorra" },
    { value: "244", code: "AO", name: "Angola" },
    { value: "1268717", code: "AI", name: "Anguilla" },
    { value: "1264", code: "AI", name: "Anguilla" },
    { value: "6721", code: "AQ", name: "Antarctica" },
    { value: "1268", code: "AG", name: "Antigua and Barbuda" },
    { value: "54", code: "AR", name: "Argentina" },
    { value: "374", code: "AM", name: "Armenia" },
    { value: "297", code: "AW", name: "Aruba" },
    { value: "247", code: "AC", name: "Ascension Island" },
    { value: "61", code: "AU", name: "Australia" },
    { value: "43", code: "AT", name: "Austria" },
    { value: "994", code: "AZ", name: "Azerbaijan" },
    { value: "1242", code: "BS", name: "Bahamas" },
    { value: "973", code: "BH", name: "Bahrain" },
    { value: "880", code: "BD", name: "Bangladesh" },
    { value: "1246", code: "BB", name: "Barbados" },
    { value: "375", code: "BY", name: "Belarus" },
    { value: "32", code: "BE", name: "Belgium" },
    { value: "501", code: "BZ", name: "Belize" },
    { value: "229", code: "BJ", name: "Benin" },
    { value: "1441", code: "BM", name: "Bermuda" },
    { value: "975", code: "BT", name: "Bhutan" },
    { value: "591", code: "BO", name: "Bolivia" },
    { value: "387", code: "BA", name: "Bosnia and Herzegovina" },
    { value: "267", code: "BW", name: "Botswana" },
    { value: "55", code: "BR", name: "Brazil" },
    { value: "1284", code: "VG", name: "British Virgin Islands" },
    { value: "673", code: "BN", name: "Brunei" },
    { value: "359", code: "BG", name: "Bulgaria" },
    { value: "226", code: "BF", name: "Burkina Faso" },
    { value: "257", code: "BI", name: "Burundi" },
    { value: "855", code: "KH", name: "Cambodia" },
    { value: "237", code: "CM", name: "Cameroon" },
    { value: "1", code: "CA", name: "Canada" },
    { value: "238", code: "CV", name: "Cape Verde" },
    { value: "599", code: "AN", name: "Caribbean Netherlands" },
    { value: "1345", code: "KY", name: "Cayman Islands" },
    { value: "236", code: "CF", name: "Central African Republic" },
    { value: "235", code: "TD", name: "Chad" },
    { value: "56", code: "CL", name: "Chile" },
    { value: "61891006", code: "CX", name: "Christmas Island" },
    { value: "6189164", code: "CX", name: "Christmas Island" },
    { value: "61891008", code: "CX", name: "Christmas Island" },
    { value: "61891007", code: "CX", name: "Christmas Island" },
    { value: "61891010", code: "CC", name: "Cocos Islands" },
    { value: "6189162", code: "CC", name: "Cocos Islands" },
    { value: "61891012", code: "CC", name: "Cocos Islands" },
    { value: "61891011", code: "CC", name: "Cocos Islands" },
    { value: "57", code: "CO", name: "Colombia" },
    { value: "269", code: "KM", name: "Comoros" },
    { value: "242", code: "CG", name: "Congo" },
    { value: "682", code: "CK", name: "Cook Islands" },
    { value: "506", code: "CR", name: "Costa Rica" },
    { value: "385", code: "HR", name: "Croatia" },
    { value: "53", code: "CU", name: "Cuba" },
    { value: "5999", code: "CW", name: "Curacao" },
    { value: "357", code: "CY", name: "Cyprus" },
    { value: "420", code: "CZ", name: "Czech Republic" },
    { value: "243", code: "CD", name: "Democratic Republic of Congo" },
    { value: "45", code: "DK", name: "Denmark" },
    { value: "246", code: "DG", name: "Diego Garcia" },
    { value: "253", code: "DJ", name: "Djibouti" },
    { value: "1767", code: "DM", name: "Dominica" },
    { value: "1849", code: "DO", name: "Dominican Republic" },
    { value: "1829", code: "DO", name: "Dominican Republic" },
    { value: "1809", code: "DO", name: "Dominican Republic" },
    { value: "670", code: "TL", name: "East Timor" },
    { value: "593", code: "EC", name: "Ecuador" },
    { value: "20", code: "EG", name: "Egypt" },
    { value: "503", code: "SV", name: "El Salvador" },
    { value: "240", code: "GQ", name: "Equatorial Guinea" },
    { value: "291", code: "ER", name: "Eritrea" },
    { value: "372", code: "EE", name: "Estonia" },
    { value: "251", code: "ET", name: "Ethiopia" },
    { value: "500", code: "FK", name: "Falkland Islands" },
    { value: "298", code: "FO", name: "Faroe Islands" },
    { value: "679", code: "FJ", name: "Fiji" },
    { value: "358", code: "FI", name: "Finland" },
    { value: "33", code: "FR", name: "France" },
    { value: "594", code: "GF", name: "French Guiana" },
    { value: "689", code: "PF", name: "French Polynesia" },
    { value: "241", code: "GA", name: "Gabon" },
    { value: "220", code: "GM", name: "Gambia" },
    { value: "995", code: "GE", name: "Georgia" },
    { value: "233", code: "GH", name: "Ghana" },
    { value: "350", code: "GI", name: "Gibraltar" },
    { value: "30", code: "GR", name: "Greece" },
    { value: "299", code: "GL", name: "Greenland" },
    { value: "1473", code: "GD", name: "Grenada" },
    { value: "590", code: "GP", name: "Guadeloupe" },
    { value: "1671", code: "GU", name: "Guam" },
    { value: "502", code: "GT", name: "Guatemala" },
    { value: "224", code: "GN", name: "Guinea" },
    { value: "245", code: "GW", name: "Guinea-bissau" },
    { value: "592", code: "GY", name: "Guyana" },
    { value: "509", code: "HT", name: "Haiti" },
    { value: "504", code: "HN", name: "Honduras" },
    { value: "852", code: "HK", name: "Hong Kong" },
    { value: "36", code: "HU", name: "Hungary" },
    { value: "354", code: "IS", name: "Iceland" },
    { value: "91", code: "IN", name: "India" },
    { value: "62", code: "ID", name: "Indonesia" },
    { value: "98", code: "IR", name: "Iran" },
    { value: "964", code: "IQ", name: "Iraq" },
    { value: "353", code: "IE", name: "Ireland" },
    { value: "972", code: "IL", name: "Israel" },
    { value: "39", code: "IT", name: "Italy" },
    { value: "225", code: "CI", name: "Ivory Coast" },
    { value: "1876", code: "JM", name: "Jamaica" },
    { value: "962", code: "JO", name: "Jordan" },
    { value: "7705", code: "KZ", name: "Kazakhstan" },
    { value: "772", code: "KZ", name: "Kazakhstan" },
    { value: "7701", code: "KZ", name: "Kazakhstan" },
    { value: "7776", code: "KZ", name: "Kazakhstan" },
    { value: "7707", code: "KZ", name: "Kazakhstan" },
    { value: "7778", code: "KZ", name: "Kazakhstan" },
    { value: "771", code: "KZ", name: "Kazakhstan" },
    { value: "7771", code: "KZ", name: "Kazakhstan" },
    { value: "7700", code: "KZ", name: "Kazakhstan" },
    { value: "7775", code: "KZ", name: "Kazakhstan" },
    { value: "7785", code: "KZ", name: "Kazakhstan" },
    { value: "7708", code: "KZ", name: "Kazakhstan" },
    { value: "7777", code: "KZ", name: "Kazakhstan" },
    { value: "776", code: "KZ", name: "Kazakhstan" },
    { value: "7702", code: "KZ", name: "Kazakhstan" },
    { value: "7788", code: "KZ", name: "Kazakhstan" },
    { value: "7747", code: "KZ", name: "Kazakhstan" },
    { value: "254", code: "KE", name: "Kenya" },
    { value: "686", code: "KI", name: "Kiribati" },
    { value: "38138", code: "KV", name: "Kosovo" },
    { value: "38649", code: "KV", name: "Kosovo" },
    { value: "38129", code: "KV", name: "Kosovo" },
    { value: "37745", code: "KV", name: "Kosovo" },
    { value: "383", code: "KV", name: "Kosovo" },
    { value: "38128", code: "KV", name: "Kosovo" },
    { value: "37744", code: "KV", name: "Kosovo" },
    { value: "38139", code: "KV", name: "Kosovo" },
    { value: "38643", code: "KV", name: "Kosovo" },
    { value: "965", code: "KW", name: "Kuwait" },
    { value: "996", code: "KG", name: "Kyrgyzstan" },
    { value: "856", code: "LA", name: "Laos" },
    { value: "371", code: "LV", name: "Latvia" },
    { value: "961", code: "LB", name: "Lebanon" },
    { value: "266", code: "LS", name: "Lesotho" },
    { value: "231", code: "LR", name: "Liberia" },
    { value: "218", code: "LY", name: "Libya" },
    { value: "423", code: "LI", name: "Liechtenstein" },
    { value: "370", code: "LT", name: "Lithuania" },
    { value: "352", code: "LU", name: "Luxembourg" },
    { value: "853", code: "MO", name: "Macau" },
    { value: "389", code: "MK", name: "Macedonia" },
    { value: "261", code: "MG", name: "Madagascar" },
    { value: "265", code: "MW", name: "Malawi" },
    { value: "60", code: "MY", name: "Malaysia" },
    { value: "960", code: "MV", name: "Maldives" },
    { value: "223", code: "ML", name: "Mali" },
    { value: "356", code: "MT", name: "Malta" },
    { value: "1670", code: "MP", name: "Mariana Islands" },
    { value: "692", code: "MH", name: "Marshall Islands" },
    { value: "596", code: "MQ", name: "Martinique" },
    { value: "222", code: "MR", name: "Mauritania" },
    { value: "230", code: "MU", name: "Mauritius" },
    { value: "262269", code: "YT", name: "Mayotte Island" },
    { value: "262639", code: "YT", name: "Mayotte Island" },
    { value: "52", code: "MX", name: "Mexico" },
    { value: "691", code: "FM", name: "Micronesia" },
    { value: "373", code: "MD", name: "Moldova" },
    { value: "377", code: "MC", name: "Monaco" },
    { value: "976", code: "MN", name: "Mongolia" },
    { value: "382", code: "ME", name: "Montenegro" },
    { value: "1664", code: "MS", name: "Montserrat" },
    { value: "212", code: "MA", name: "Morocco" },
    { value: "258", code: "MZ", name: "Mozambique" },
    { value: "95", code: "MM", name: "Myanmar" },
    { value: "264", code: "NA", name: "Namibia" },
    { value: "674", code: "NR", name: "Nauru" },
    { value: "977", code: "NP", name: "Nepal" },
    { value: "31", code: "NL", name: "Netherlands" },
    { value: "687", code: "NC", name: "New Caledonia" },
    { value: "64", code: "NZ", name: "New Zealand" },
    { value: "505", code: "NI", name: "Nicaragua" },
    { value: "227", code: "NE", name: "Niger" },
    { value: "234", code: "NG", name: "Nigeria" },
    { value: "683", code: "NU", name: "Niue" },
    { value: "6723", code: "NF", name: "Norfolk Island" },
    { value: "850", code: "KP", name: "North Korea" },
    { value: "47", code: "NO", name: "Norway" },
    { value: "968", code: "OM", name: "Oman" },
    { value: "92", code: "PK", name: "Pakistan" },
    { value: "680", code: "PW", name: "Palau" },
    { value: "97222", code: "PS", name: "Palestine" },
    { value: "97292", code: "PS", name: "Palestine" },
    { value: "97256", code: "PS", name: "Palestine" },
    { value: "97282", code: "PS", name: "Palestine" },
    { value: "97259", code: "PS", name: "Palestine" },
    { value: "97242", code: "PS", name: "Palestine" },
    { value: "970", code: "PS", name: "Palestine" },
    { value: "507", code: "PA", name: "Panama" },
    { value: "675", code: "PG", name: "Papua New Guinea" },
    { value: "595", code: "PY", name: "Paraguay" },
    { value: "51", code: "PE", name: "Peru" },
    { value: "63", code: "PH", name: "Philippines" },
    { value: "48", code: "PL", name: "Poland" },
    { value: "351", code: "PT", name: "Portugal" },
    { value: "1939", code: "PR", name: "Puerto Rico" },
    { value: "1787", code: "PR", name: "Puerto Rico" },
    { value: "974", code: "QA", name: "Qatar" },
    { value: "262", code: "RE", name: "Reunion Island" },
    { value: "40", code: "RO", name: "Romania" },
    { value: "250", code: "RW", name: "Rwanda" },
    { value: "1869", code: "KN", name: "Saint Kitts and Nevis" },
    { value: "1758", code: "LC", name: "Saint Lucia" },
    { value: "1784", code: "VC", name: "Saint Vincent and the Grenadines" },
    { value: "685", code: "WS", name: "Samoa" },
    { value: "378", code: "SM", name: "San Marino" },
    { value: "239", code: "ST", name: "Sao Tome and Principe" },
    { value: "966", code: "SA", name: "Saudi Arabia" },
    { value: "221", code: "SN", name: "Senegal" },
    { value: "381", code: "RS", name: "Serbia" },
    { value: "248", code: "SC", name: "Seychelles" },
    { value: "232", code: "SL", name: "Sierra Leone" },
    { value: "1721", code: "SX", name: "Sint Maarten" },
    { value: "5995", code: "SX", name: "Sint Maarten" },
    { value: "421", code: "SK", name: "Slovakia" },
    { value: "386", code: "SI", name: "Slovenia" },
    { value: "677", code: "SB", name: "Solomon Islands" },
    { value: "252", code: "SO", name: "Somalia" },
    { value: "27", code: "ZA", name: "South Africa" },
    { value: "211", code: "SS", name: "South Sudan" },
    { value: "34", code: "ES", name: "Spain" },
    { value: "94", code: "LK", name: "Sri Lanka" },
    { value: "290", code: "SH", name: "St Helena" },
    { value: "508", code: "PM", name: "St Pierre and Miquelon" },
    { value: "249", code: "SD", name: "Sudan" },
    { value: "597", code: "SR", name: "Suriname" },
    { value: "268", code: "SZ", name: "Swaziland" },
    { value: "46", code: "SE", name: "Sweden" },
    { value: "41", code: "CH", name: "Switzerland" },
    { value: "963", code: "SY", name: "Syria" },
    { value: "886", code: "TW", name: "Taiwan" },
    { value: "992", code: "TJ", name: "Tajikistan" },
    { value: "255", code: "TZ", name: "Tanzania" },
    { value: "66", code: "TH", name: "Thailand" },
    { value: "228", code: "TG", name: "Togo" },
    { value: "690", code: "TK", name: "Tokelau" },
    { value: "676", code: "TO", name: "Tonga" },
    { value: "1868", code: "TT", name: "Trinidad and Tobago" },
    { value: "216", code: "TN", name: "Tunisia" },
    { value: "90", code: "TR", name: "Turkey" },
    { value: "993", code: "TM", name: "Turkmenistan" },
    { value: "1649", code: "TC", name: "Turks and Caicos Islands" },
    { value: "688", code: "TV", name: "Tuvalu" },
    { value: "256", code: "UG", name: "Uganda" },
    { value: "971", code: "AE", name: "United Arab Emirates" },
    { value: "44", code: "GB", name: "United Kingdom" },
    { value: "598", code: "UY", name: "Uruguay" },
    { value: "1340", code: "VI", name: "US Virgin Islands" },
    { value: "998", code: "UZ", name: "Uzbekistan" },
    { value: "678", code: "VU", name: "Vanuatu" },
    { value: "379", code: "VA", name: "Vatican City" },
    { value: "58", code: "VE", name: "Venezuela" },
    { value: "681", code: "WF", name: "Wallis and Futuna Islands" },
    { value: "967", code: "YE", name: "Yemen" },
    { value: "260", code: "ZM", name: "Zambia" },
    { value: "263", code: "ZW", name: "Zimbabwe" },
];

export default country;