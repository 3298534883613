import { configureStore, createSlice } from '@reduxjs/toolkit'

let login = createSlice({
    name: 'login',
    initialState: { value: false },
    reducers: {
        checkLogin(state, action) {
            state.value = action.payload
        }
    }
})

let SelectType = createSlice({
    name: 'SelectType',
    initialState: { value: 0 },
    reducers: {
        checkSelectType(state, action) {
            let s = action.payload
            if (s == "SUPER") state.value = 0
            else if (s == "MANAGER") state.value = 1
            else state.value = 2
        }
    }
})

let Darkmode = createSlice({
    name: 'Darkmode',
    initialState: { value: false },
    reducers: {
        checkMode(state, action) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                const newobj = {
                    value: obj.value,
                    expire: obj.expire,
                    mode: action.payload ? "dark" : "light",
                }
                const newobjString = JSON.stringify(newobj);
                localStorage.setItem('token', newobjString)
            }
            state.value = action.payload
        }
    }
})

let withdrawAlert = createSlice({
    name: 'withdrawAlert',
    initialState: { value: 0 },
    reducers: {
        change_withdrawAlert(state, action) {
            state.value = action.payload
        }
    }
})

let sidebar_dropdown = createSlice({
    name: 'sidebar_dropdown',
    initialState: [true, true],
    reducers: {
        change_sidebar_dropdown(state, action) {
            let n = action.payload
            state[n.num] = n.value
            localStorage.setItem('sidebar_dropdown', state)
        }
    }
})

let ex_name = createSlice({
    name: 'ex_name',
    initialState: { value: "NAMUBIT" },
    reducers: {
        checkName(state, action) {
            state.value = action.payload
        }
    }
})

export let { checkLogin } = login.actions
export let { checkSelectType } = SelectType.actions
export let { checkMode } = Darkmode.actions
export let { change_withdrawAlert } = withdrawAlert.actions
export let { change_sidebar_dropdown } = sidebar_dropdown.actions
export let { checkName } = ex_name.actions

export default configureStore({
    reducer: {
        login: login.reducer,
        SelectType: SelectType.reducer,
        Darkmode: Darkmode.reducer,
        withdrawAlert: withdrawAlert.reducer,
        sidebar_dropdown: sidebar_dropdown.reducer,
        ex_name: ex_name.reducer
    }
}) 