import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import Page from './Page'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';

function Board() {
    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const [tab, setTab] = useState(0)
    const [tab1, setTab1] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    let arr = [1, 2, 3, 4, 5]
    let arr1 = [1, 2, 3, 4, 1, 2, 3, 4, 5]
    return (
        <div className='main'>
            <Sidebar page={16} />
            <div className={`main-box ${darkname}`}>
                <div className='bulletin-1'>
                    <div className='bulletin-1-1'>
                        <div className={tab === 0 ? `bulletin-1-1-tit on ${darkname}` : `bulletin-1-1-tit ${darkname}`} onClick={() => { setTab(0); setTab1(false) }}>In Progress</div>
                        <div className={tab === 1 ? `bulletin-1-1-tit on ${darkname}` : `bulletin-1-1-tit ${darkname}`} onClick={() => { setTab(1); setTab1(false) }}>Completed</div>
                        <div className={tab === 2 ? `bulletin-1-1-tit on flex ${darkname}` : `bulletin-1-1-tit flex ${darkname}`} onClick={() => { setTab(2); setTab1(false) }}>
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                            />
                            <div>~</div>
                            <DatePicker
                                dateFormat='yyyy-MM-dd' // 날짜 형태
                                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                selected={selectedDate1}
                                onChange={(date) => setSelectedDate1(date)}
                            />
                        </div>
                    </div>
                    {
                        tab1
                            ?
                            ""
                            :
                            <div className={`bulletin-1-1-tit ${darkname}`} onClick={() => setTab1(e => !e)}>Create</div>
                    }
                </div>
                {
                    tab1
                        ?
                        <CreateItem darkname={darkname} />
                        :
                        tab === 0
                            ?
                            <div className={`bulletin-2 ${darkname}`}>
                                {
                                    arr.map(function (a, i) {
                                        return (
                                            <BulletinItem key={i} item={a} darkname={darkname} />
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className={`bulletin-4 ${darkname}`}>
                                {
                                    arr1.map(function (a, i) {
                                        return (
                                            <Completed key={i} item={a} darkname={darkname} />
                                        )
                                    })
                                }
                            </div>
                }

            </div>
        </div>
    )
}

function Completed({ darkname }) {
    return (
        <div className='bulletin-4-1'>
            <div className='bulletin-4-end'>
                <div className='bulletin-4-end-1'>ENDED EVENT</div>
            </div>
            <img src='/img/n_8.png' />
            <div className={`bulletin-4-2 ${darkname}`}>
                <div className={`bulletin-4-2-1 ${darkname}`}>Title</div>
                <div className={`bulletin-4-2-2 ${darkname}`}>contentcontentcontentcontentcontentcontentcontentcontentcontentcontentcontentco</div>
                <div className={`bulletin-4-2-3 ${darkname}`}>
                    <div>2023.11.11</div>
                    <div>-3 Day</div>
                </div>
            </div>
        </div >
    )
}

function CreateItem({ darkname }) {
    const [title, setTitle] = useState("")

    const onChangeTitle = (e) => {
        setTitle(e.target.value)
    }
    return (
        <div className={`bulletin-3 ${darkname}`}>
            <div className={`bulletin-3-1 ${darkname}`}>
                <div className={`bulletin-3-1-1 ${darkname}`}>Upload</div>
                <div className={`bulletin-3-1-1 ${darkname}`}>Save</div>
            </div>
            <div className='bulletin-3-2'>
                <div className='bulletin-3-2-1'>
                    <div className='bulletin-3-2-1-tit'>Title</div>
                    <div className={`bulletin-3-2-1-txt ${darkname}`}>
                        <input value={title} onChange={onChangeTitle} />
                    </div>
                </div>
                <div className='bulletin-3-2-1'>
                    <div className='bulletin-3-2-1-tit'>URL</div>
                    <div className={`bulletin-3-2-1-txt ${darkname}`}>
                        <input value={title} onChange={onChangeTitle} />
                    </div>
                </div>
                <div className='bulletin-3-2-2'>
                    <div className='bulletin-3-2-2-tit'>Upload Image File</div>
                    <div className='bulletin-3-2-2-txt'>
                        <div className={`bulletin-3-2-2-btn ${darkname}`}>Import</div>
                        <div className={`bulletin-3-2-2-box ${darkname}`}></div>
                    </div>
                </div>
                <div className='bulletin-3-2-1'>
                    <div className='bulletin-3-2-1-tit'>Posting Time</div>
                    <div className='bulletin-3-2-3'>
                        <div className={`bulletin-3-2-3-tit ${darkname}`}>2023-11-07</div>
                        <div className={`bulletin-3-2-3-tit ${darkname}`}>00:00</div>
                    </div>
                </div>
                <div className='bulletin-3-2-1'>
                    <div className='bulletin-3-2-1-tit'>End Time</div>
                    <div className='bulletin-3-2-3'>
                        <div className={`bulletin-3-2-3-tit ${darkname}`}>2023-11-07</div>
                        <div className={`bulletin-3-2-3-tit ${darkname}`}>00:00</div>
                    </div>
                </div>
                <div className='bulletin-3-2-2'>
                    <div className='bulletin-3-2-2-tit'>Write Content</div>
                    <div className={`bulletin-3-2-4 ${darkname}`}>
                    </div>
                </div>
            </div>
        </div>
    )
}

function BulletinItem({ item, darkname }) {
    const [type, setType] = useState(0)
    const [type1, setType1] = useState(1)

    const onMouseEnter = () => {
        setType(1)
    }

    const onMouseLeave = () => {
        setType(0)
    }

    return (
        <div className='bulletin-2-1' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {
                type === 1
                    ?
                    <div className='bulletin-2-hover'>
                        <div className='bulletin-2-hover-1' onClick={() => setType1(1)}>Public</div>
                        <div className='bulletin-2-hover-1' onClick={() => setType1(0)}>Privacy</div>
                    </div>
                    :
                    ""
            }
            {
                type1 === 0
                    ?
                    < div className='bulletin-2-privacy'>
                        <div>Privacy</div>
                    </div>
                    :
                    <div className='bulletin-2-public'>
                        <div>Public</div>
                    </div>

            }
            <img src={`/img/event/event${item}.jpg`} />
            <div className={`bulletin-2-2 ${darkname}`}>
                <div className={`bulletin-2-2-1 ${darkname}`}>New Listing ~ Welcom</div>
                <div className={`bulletin-2-2-2 ${darkname}`}>New Listing ~ Welcom New Listing ~ Welcom</div>
                <div className={`bulletin-2-2-3 ${darkname}`}>
                    <div>2023.11.11</div>
                    <div>-1 Day</div>
                </div>
            </div>
        </div >
    )
}

export default Board;