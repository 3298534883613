import '../css/main.css'
import { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.css';

function Message({ message }) {
    const [modal, setModal] = useState(false)

    useEffect(() => {
        if (message.code != -1) {
            onChangeModal()
        }
    }, [message])

    const onChangeModal = () => {
        setModal(true)
        setTimeout(() => {
            setModal(false)
        }, 2000)
    }

    return (
        <>
            {
                modal
                    ?
                    <div className={`buy-modal copy`}>
                        <div>
                            {
                                message?.code === 0
                                    ?
                                    <i className="fa-regular fa-circle-check" style={{ color: "#20B26C" }}></i>
                                    :
                                    <i className="fa-regular fa-circle-xmark" style={{ color: "#EF454A" }}></i>
                            }
                        </div>
                        <div>{message?.msg}</div>
                    </div>
                    :
                    ""
            }
        </>
    )
}

export default Message;