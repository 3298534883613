import '../css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../../Nav/sidebar';
import NewPage from '../NewPage'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';
import { SyncLoader } from "react-spinners";
import { removeZeros, changeNum } from '../common/function'
import base64 from "base-64";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Admin_Day_Settlement() {
    let login = useSelector((state) => { return state.login.value; });
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        let objString = localStorage.getItem('token')
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = base64.decode(payload);
            let sub = JSON.parse(dec).authority
            if (sub != "SUPER") {
                navigate('/')
            }
        }
    }, [login])

    return (
        <div className='main'>
            <Sidebar page={19} />
            <Day_Settlement />
        </div>
    )
}

function Day_Settlement() {
    const [page, setPage] = useState(1)
    const [spinner, setSpinner] = useState(false)

    const [staySettlement, setStaySettlement] = useState([])
    const [total, setTotal] = useState([0, 0, 0, 0])

    const [clickBtn, setClickBtn] = useState(false)

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        onSpinner()
        StaySettlement()
    }, [clickBtn])

    const StaySettlement = async () => {
        await axios.get(process.env.REACT_APP_BROKER_API + '/api/v1/broker/admin/get/settlement/today/confirm', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                setStaySettlement(tmp?.tradeListResult??[])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        let t = 0
        let bn = 0
        let o = 0
        let by = 0

        if (staySettlement && staySettlement.length > 0) {
            for (let i = 0; i < staySettlement.length; i++) {
                let tmp = staySettlement[i]
                t += Number(tmp.binance_income) + Number(tmp.okx_income) + Number(tmp.bybit_income)
                bn += Number(tmp.binance_income)
                o += Number(tmp.okx_income)
                by += Number(tmp.bybit_income)
            }
            setTotal([t, bn, o, by])
        }
    }, [staySettlement])

    let slice_data = staySettlement.slice((page - 1) * 15, (page) * 15)

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (staySettlement) {
            for (let i = 0; i < staySettlement.length; i++) {
                let t = staySettlement[i]
                arr.push({
                    "레퍼러": t.broker,
                    "배율": t?.rebate_rate ?? "-",
                    "Bn_누적정산액": t?.binance_volume ?? "-",
                    "Bn_정산액": t?.binance_income ?? "-",
                    "Bn_ID": t?.binance_tx_id ?? "-",
                    "O_누적정산액": t?.okx_volume ?? "-",
                    "O_정산액": t?.okx_income ?? "-",
                    "O_ID": t?.okx_tx_id ?? "-",
                    "By_누적정산액": t?.bybit_volume ?? "-",
                    "By_정산액": t?.bybit_income ?? "-",
                    "By_ID": t?.bybit_tx_id ?? "-"
                })
            }
        }

        Excel(arr)
    }

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ fontWeight: "bold" }}>Day Settlement</div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className={`reward-box-1 ${darkname}`}>
                    <div className='reward-box-1-tit'>Total Settlements (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{(total[0]).toFixed(4)}</div>
                    </div>
                </div>
                <div className={`reward-box-1 ${darkname}`}>
                    <div className='reward-box-1-tit'>Binance (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{(total[1]).toFixed(4)}</div>
                    </div>
                </div>
                <div className={`reward-box-1 ${darkname}`}>
                    <div className='reward-box-1-tit'>OKX (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{(total[2]).toFixed(4)}</div>
                    </div>
                </div>
                <div className={`reward-box-1 ${darkname}`}>
                    <div className='reward-box-1-tit'>Bybit (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{(total[3]).toFixed(4)}</div>
                    </div>
                </div>
            </div>
            <div className='tradingType-1'>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Referral</div>
                    <div>Rate</div>
                    <div>Binance<br /> Total Settlements</div>
                    <div>Binance Settlements</div>
                    <div>Binance ID</div>
                    <div>OKX<br /> Total Settlements</div>
                    <div>OKX Settlements</div>
                    <div>OKX ID</div>
                    <div>Bybit<br /> Total Settlements</div>
                    <div>Bybit Settlements</div>
                    <div>Bybit ID</div>
                    <div>Check</div>
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <SettlementItem key={i} item={a} darkname={darkname} setClickBtn={setClickBtn} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={staySettlement} page={page} setPage={setPage} maxFirstPage={15} />
                                </>

                    }
                </div>
            </div>
        </div>
    )
}

function SettlementItem({ item, darkname, setClickBtn }) {
    const onCheck = async () => {
        if (window.confirm("Are you sure you want to approve the review?")) {
            await axios.post(process.env.REACT_APP_BROKER_API + '/api/v1/broker/admin/get/settlement/today/confirm', {
                withCredentials: true,
                id: item.seq
            }).then((response) => {
                let tmp = response.data
                if (tmp?.c === 1000) {
                    setClickBtn(e => !e)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{item.broker}</div>
            <div className='td'>{item?.rebate_rate ?? '-'}</div>
            <div className='td right'>{item?.binance_volume ?? '-'}</div>
            <div className='td right'>{item?.binance_income ?? '-'}</div>
            <div className='td right'>{item?.binance_tx_id ?? '-'}</div>
            <div className='td right'>{item?.okx_volume ?? '-'}</div>
            <div className='td right'>{item?.okx_income ?? '-'}</div>
            <div className='td right'>{item?.okx_tx_id ?? '-'}</div>
            <div className='td right'>{item?.bybit_volume ?? '-'}</div>
            <div className='td right'>{item?.bybit_income ?? '-'}</div>
            <div className='td right'>{item?.bybit_tx_id ?? '-'}</div>
            <div className='td'>
                {
                    item.binance_confirm == "WAIT" || item.okx_confirm == "WAIT" || item.bybit_confirm == "WAIT"
                        ?
                        <div className='withdrawitem-btn' onClick={() => onCheck()}>Check</div>
                        :
                        <div>Pending</div>
                }

            </div>
        </div>
    )
}


const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Wait_Settlement`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Referral", "Rate", "Binance Total Settlements", "Binance Settlements", "Binance ID", "OKX Total Settlements", "OKX Settlements", "OKX ID", "Bybit Total Settlements", "Bybit Settlements", "Bybit ID"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.레퍼러 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.배율 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Bn_누적정산액 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Bn_정산액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Bn_ID },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.O_누적정산액 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.O_정산액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.O_ID },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.By_누적정산액 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.By_정산액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.By_ID }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 }
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};


function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

export default Admin_Day_Settlement;