import { useEffect, useState } from 'react';
import './css/main.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAnglesRight, faAnglesLeft, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from 'react-redux'

const createArr = (n) => {
    const iArr = new Array(n);
    for (var i = 0; i < n; i++)  iArr[i] = i + 1;
    return iArr;
}

function Page({ page, totalpage, setNewpage, btn, tab, type }) {
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    let pageLimit = Math.min(totalpage + 1, 5);

    const [blockNum, setBlockNum] = useState(0);
    let lastBlock = Math.ceil(totalpage / 5) - 1

    const v = Number(blockNum * pageLimit);
    const iArr = createArr(totalpage);
    let pArr = iArr.slice(v, Number(pageLimit) + v);
    
    useEffect(() => {
        if (type != 0) {
            setBlockNum(0)
            setNewpage(1)
        }
    }, [btn, tab])

    const firstPage = () => {
        setBlockNum(0)
        setNewpage(1)
    }

    const lastPage = () => {
        setBlockNum(Math.ceil(totalpage / pageLimit) - 1)
        setNewpage(totalpage)
    }

    const prevPage = () => {
        setBlockNum(n => n - 1);
        setNewpage((blockNum) * pageLimit);
    }
    const nextPage = () => {
        setBlockNum(n => n + 1);
        setNewpage((blockNum + 1) * pageLimit + 1);
    }

    const nowPage = (n) => {
        setNewpage(n)
    }

    return (
        <>
            <div className='main-paging'>
                <div className={`main-paging-pages ${darkname}`}>
                    {
                        page <= 5
                            ?
                            ""
                            :
                            <>
                                <div className={`main-paging-btn ${darkname}`} onClick={firstPage}>
                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                </div>
                                <div className={`main-paging-btn ${darkname}`} onClick={prevPage}>
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </div>
                            </>
                    }
                    {pArr.map(function (a, i) {
                        if (page == a) {
                            return (
                                <span key={i} className='active'>{a}</span>
                            )
                        }
                        else {
                            return (<span key={i} className='' onClick={() => { nowPage(a) }}>{a}</span>)
                        }
                    })}
                    {
                        blockNum === lastBlock
                            ?
                            ""
                            :
                            <>
                                <div className={`main-paging-btn ${darkname}`} onClick={nextPage}>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                                <div className={`main-paging-btn ${darkname}`} onClick={lastPage}>
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    );
}

export default Page;