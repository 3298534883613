import '../css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faCaretUp, faCaretDown, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../../Nav/sidebar';
import NewPage from '../NewPage'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';
import { SyncLoader } from "react-spinners";
import { removeZeros, changeNum } from '../common/function'
import base64 from "base-64";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

let check_click = false

function Admin_Referral_List() {
    let login = useSelector((state) => { return state.login.value; });
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        let objString = localStorage.getItem('token')
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = base64.decode(payload);
            let sub = JSON.parse(dec).authority
            if (sub != "SUPER") {
                navigate('/')
            }
        }
    }, [login])

    return (
        <div className='main'>
            <Sidebar page={21} />
            <Referral_List />
        </div>
    )
}

function Referral_List() {
    const [btn, setBtn] = useState(false)
    const [search, setSearch] = useState("")
    const [select, setSelect] = useState(false)
    const [selectValue, setSelectValue] = useState("Referral")
    const [page, setPage] = useState(1)
    const [refData, setRefData] = useState([])
    const [refList, setRefList] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [reloade, setReloade] = useState(false)
    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        RefData()
    }, [reloade])

    const RefData = async () => {
        await axios.get(process.env.REACT_APP_BROKER_API + '/api/v1/broker/admin/get/user', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                setRefData(tmp)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        let arr = []
        if (refData && refData.length > 0) {
            if (selectValue == "Referral" && search.length > 0) {
                arr = refData.filter((p) => {
                    if (p.broker) return p.broker.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else if (selectValue == "Level" && search.length > 0) {
                arr = refData.filter((p) => {
                    if (p.broker_grade) return p.broker_grade.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                })
            }
            else {
                arr = refData
            }
            setRefList(arr)
        }
    }, [selectValue, search, refData])

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (refList) {
            for (let i = 0; i < refList.length; i++) {
                let t = refList[i]
                let date = t.regist_date.split('T')
                let date1 = date[1].split('.000Z')
                arr.push({
                    "레퍼럴": t.broker,
                    "계층": Number(t.broker_grade) ?? 0,
                    "하위레퍼럴수": Number(t.broker_count) ?? 0,
                    "트레이더수": Number(t.trade_count) ?? 0,
                    "총정산액": (Number(t.binance_income) + Number(t.okx_income)).toFixed(8) ?? 0,
                    "총자산": (Number(t.binance_income) + Number(t.okx_income)).toFixed(8) ?? 0,
                    "사용가능": (Number(t.binance_income) + Number(t.okx_income)).toFixed(8) ?? 0,
                    "잠긴자산": (Number(t.binance_income) + Number(t.okx_income)).toFixed(8) ?? 0,
                    "등록일시": `${date[0]} ${date1[0]}`,
                })
            }
        }
        Excel(arr)
    }

    let slice_data = refList.slice((page - 1) * 15, (page) * 15)

    return (
        <div className={`main-box ${darkname}`}>
            {
                btn
                    ?
                    <RefModal setBtn={setBtn} setReloade={setReloade} darkname={darkname}/>
                    :
                    ""
            }
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ fontWeight: "bold" }}>Top-Level Referral</div>
            </div>
            <div className={`reward-box ${darkname}`}>
                {
                    refData.map(function (a, i) {
                        if (a?.broker_grade == "1") {
                            return (
                                <TopRef key={i} item={a} darkname={darkname} />
                            )
                        }
                    })
                }
                {/* <div className={`admin-ref ${darkname}`} onClick={() => setBtn(true)} style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faCirclePlus} className='plus' />
                </div> */}
            </div>
            <div className='tradingType-1'>
                <div className='member-1'>
                    <div className={`member-2-search ${darkname}`}>
                        <div className={`member-1-select ${darkname}`} onClick={() => setSelect(e => !e)}>
                            <div className={`member-1-select-1 ${darkname}`}>{selectValue}</div>
                            {
                                select
                                    ?
                                    <FontAwesomeIcon icon={faSortUp} />
                                    :
                                    <FontAwesomeIcon icon={faSortDown} />
                            }
                            {
                                select
                                    ? <>
                                        <div className='modal' onClick={() => setSelect(true)}></div>
                                        <div className={`member-1-select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue("Referral"); setSelect(true) }}>Referral</div>
                                            <div onClick={() => { setSelectValue("Level"); setSelect(true) }}>Level</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <FontAwesomeIcon icon={faWindowMinimize} className='line' />
                        <div className={`member-2-search-1 ${darkname}`}>
                            <input value={search} onChange={onChangeSearch} placeholder='Search' />
                            <FontAwesomeIcon icon={faMagnifyingGlass} className='member-1-search-ico-3' />
                            {
                                search && search.length > 0
                                    ?
                                    <FontAwesomeIcon icon={faCircleXmark} className='member-1-search-ico-2' onClick={() => { setSearch(""); check_click = false }} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Referral</div>
                    <div style={{ width: '50%' }}>Level</div>
                    <div style={{ width: '50%' }}>Sub Referral</div>
                    <div style={{ width: '50%' }}>Trader</div>
                    <div>Total Settlements</div>
                    <div>Balance</div>
                    <div>Avaliable</div>
                    <div>Locked</div>
                    <div>Regist</div>
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <Item key={i} item={a} darkname={darkname} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={refList} page={page} setPage={setPage} maxFirstPage={15} />
                                </>
                    }
                </div>
            </div>
        </div>
    )
}

function TopRef({ item, darkname }) {

    return (
        <div className={`admin-ref ${darkname}`}>
            <div className='admin-ref-1'>{item.broker}</div>
            <div className='admin-ref-2'>
                <div className={`admin-ref-2-1 ${darkname}`}>
                    <div className='admin-ref-2-1-1'>Settlements</div>
                    <div className='admin-ref-2-1-2'>{(Number(item?.binance_income ?? 0) + Number(item?.okx_income ?? 0)).toFixed(8)}</div>
                </div>
                <div className='admin-ref-2-2'>
                    <div className='admin-ref-2-2-1'>
                        <div className='admin-ref-2-2-2'>Referral</div>
                        <div className='admin-ref-2-2-3'>{item?.broker_count ?? 0}</div>
                    </div>
                    <div className='admin-ref-2-2-1' style={{ border: "none" }}>
                        <div className='admin-ref-2-2-2'>Trader</div>
                        <div className='admin-ref-2-2-3'>{item?.trade_count ?? 0}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function RefModal({ setBtn, setReloade, darkname }) {
    const [code, setCode] = useState("")
    const [uid, setUid] = useState("")

    const onCode = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "")
        setCode(onlyNumber)
    }

    const onUID = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setUid(onlyNumber)
    }

    const onSubmitReferral = async () => {
        if (window.confirm("최상위 레퍼러를 등록하시겠습니까?")) {
            await axios.post(process.env.REACT_APP_BROKER_API + '/api/v1/broker/admin/set/broker', {
                code: code,
                uid: uid
            }).then((response) => {
                let tmp = response.data
                if (tmp.c > 0) {
                    setBtn(false)
                    setReloade(e => !e)
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setBtn(false);
        }
    };

    document.addEventListener('keydown', handleEsc);

    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className={`modal-5 ${darkname}`}>
                    <div className={`modal-1-tit`}>Regist Top-Level Referral</div>
                    <div className='password'>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ fontSize: "14px", paddingBottom: "3px" }}>Referral Code</div>
                            <div className={`password-1 ${darkname}`}>
                                <input value={code} onChange={onCode} maxLength={8} placeholder='Referral Code' />
                            </div>
                        </div>
                        <div style={{ width: "100%", textAlign: "left" }}>
                            <div style={{ fontSize: "14px", paddingBottom: "3px" }}>NAMU UID</div>
                            <div className={`password-1 ${darkname}`}>
                                <input value={uid} onChange={onUID} maxLength={6} placeholder='NAMU UID' />
                            </div>
                        </div>
                    </div>
                    <div className='password-2' style={{ marginTop: "30px" }}>
                        <div className={`password-2-1 ${darkname}`} onClick={() => onSubmitReferral()}>Confirm</div>
                        <div className={`password-2-1 ${darkname}`} onClick={() => setBtn(false)}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Item({ item, darkname }) {
    let arr = item.regist_date.split('T')
    let arr1 = arr[1].split('.000Z')

    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{item.broker ?? '-'}</div>
            <div className='td' style={{ width: '50%' }}>{item.broker_grade ?? 0}</div>
            <div className='td' style={{ width: '50%' }}>{item.broker_count ?? 0}</div>
            <div className='td' style={{ width: '50%' }}>{item.trade_count ?? 0}</div>
            <div className='td right'>{(Number(item.binance_income) + Number(item.okx_income)).toFixed(8) ?? 0}</div>
            <div className='td right'>{(Number(item.binance_sum) + Number(item.okx_sum)).toFixed(8) ?? 0}</div>
            <div className='td right'>{(Number(item.binance_free) + Number(item.okx_free)).toFixed(8) ?? 0}</div>
            <div className='td right'>{(Number(item.binance_lock) + Number(item.okx_lock)).toFixed(8) ?? 0}</div>
            <div className='td'>{`${arr[0]} ${arr1[0]}` ?? '-'}</div>
        </div>
    )
}

function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Admin_Referrer`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Referral", "Level", "Sub Referral", "Trader", "Total Settlements", "Balance", "Avaliable", "Locked", "Regist"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.레퍼럴 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.계층 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.하위레퍼럴수 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.트레이더수 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.총정산액 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.총자산 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.사용가능 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.잠긴자산 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.등록일시 }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 150 },
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

export default Admin_Referral_List;