import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import Page from './Page'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';

function Notice() {
    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    const [title, setTitle] = useState("")

    const onChangeTitle = (e) => {
        setTitle(e.target.value)
    }

    return (
        <div className='main'>
            <Sidebar page={17} />
            <div className={`main-box ${darkname}`}>
                <div className='notice-1'>
                    <div className='notice-1-1'>
                        <div className={`notice-1-1-tit ${darkname}`}>Announcement</div>
                    </div>
                </div>
                <div className={`notice-2 ${darkname}`}>
                    <div className={`notice-2-1 ${darkname}`}>
                        <FontAwesomeIcon icon={faPlus} className={`notice-2-1-ico ${darkname}`} />
                        <div className={`notice-2-1-ico-1 ${darkname}`}>
                            <svg viewBox="0 0 8 13">
                                <g id="그룹_625" data-name="그룹 625" transform="translate(-627 -137)">
                                    <circle id="타원_111" data-name="타원 111" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 137)" />
                                    <circle id="타원_113" data-name="타원 113" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 142)" />
                                    <circle id="타원_115" data-name="타원 115" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 147)" />
                                    <circle id="타원_112" data-name="타원 112" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 137)" />
                                    <circle id="타원_114" data-name="타원 114" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 142)" />
                                    <circle id="타원_116" data-name="타원 116" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 147)" />
                                </g>
                            </svg>

                        </div>
                    </div>
                    <div className={`notice-2-1 ${darkname}`}>
                        <FontAwesomeIcon icon={faPlus} className={`notice-2-1-ico ${darkname}`} />
                        <div className={`notice-2-1-ico-1 ${darkname}`}>
                            <svg viewBox="0 0 8 13">
                                <g id="그룹_625" data-name="그룹 625" transform="translate(-627 -137)">
                                    <circle id="타원_111" data-name="타원 111" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 137)" />
                                    <circle id="타원_113" data-name="타원 113" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 142)" />
                                    <circle id="타원_115" data-name="타원 115" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 147)" />
                                    <circle id="타원_112" data-name="타원 112" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 137)" />
                                    <circle id="타원_114" data-name="타원 114" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 142)" />
                                    <circle id="타원_116" data-name="타원 116" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 147)" />
                                </g>
                            </svg>

                        </div>
                    </div>
                    <div className={`notice-2-1 ${darkname}`}>
                        <FontAwesomeIcon icon={faPlus} className={`notice-2-1-ico ${darkname}`} />
                        <div className={`notice-2-1-ico-1 ${darkname}`}>
                            <svg viewBox="0 0 8 13">
                                <g id="그룹_625" data-name="그룹 625" transform="translate(-627 -137)">
                                    <circle id="타원_111" data-name="타원 111" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 137)" />
                                    <circle id="타원_113" data-name="타원 113" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 142)" />
                                    <circle id="타원_115" data-name="타원 115" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 147)" />
                                    <circle id="타원_112" data-name="타원 112" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 137)" />
                                    <circle id="타원_114" data-name="타원 114" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 142)" />
                                    <circle id="타원_116" data-name="타원 116" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 147)" />
                                </g>
                            </svg>

                        </div>
                    </div>
                    <div className={`notice-2-1 ${darkname}`}>
                        <FontAwesomeIcon icon={faPlus} className={`notice-2-1-ico ${darkname}`} />
                        <div className={`notice-2-1-ico-1 ${darkname}`}>
                            <svg viewBox="0 0 8 13">
                                <g id="그룹_625" data-name="그룹 625" transform="translate(-627 -137)">
                                    <circle id="타원_111" data-name="타원 111" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 137)" />
                                    <circle id="타원_113" data-name="타원 113" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 142)" />
                                    <circle id="타원_115" data-name="타원 115" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(627 147)" />
                                    <circle id="타원_112" data-name="타원 112" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 137)" />
                                    <circle id="타원_114" data-name="타원 114" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 142)" />
                                    <circle id="타원_116" data-name="타원 116" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(632 147)" />
                                </g>
                            </svg>

                        </div>
                    </div>
                </div>
                <div className='notice-3'>
                    <div>
                        <div className='notice-3-1'>Preview</div>
                        <div className={`notice-2-1 ${darkname}`}>
                        </div>
                    </div>
                    <div className={`bulletin-3 ${darkname}`}>
                        <div className={`bulletin-3-1 ${darkname}`}>
                            <div className={`bulletin-3-1-1 ${darkname}`}>Upload</div>
                        </div>
                        <div className='bulletin-3-2'>
                            <div className='bulletin-3-2-1'>
                                <div className='bulletin-3-2-1-tit'>Title</div>
                                <div className={`bulletin-3-2-1-txt ${darkname}`}>
                                    <input value={title} onChange={onChangeTitle} />
                                </div>
                            </div>
                            <div className='bulletin-3-2-2'>
                                <div className='bulletin-3-2-2-tit'>Upload Image File</div>
                                <div className='bulletin-3-2-2-txt'>
                                    <div className={`bulletin-3-2-2-btn ${darkname}`}>Import</div>
                                    <div className={`bulletin-3-2-2-box ${darkname}`}></div>
                                </div>
                            </div>
                            <div className='bulletin-3-2-2'>
                                <div className='bulletin-3-2-2-tit'>Write Content</div>
                                <div className={`bulletin-3-2-4 ${darkname}`}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notice;