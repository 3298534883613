import './css/main.css'
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faXmark, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../Nav/sidebar';
import Page from './Page'
import NewPage from './NewPage'
import Country from './country'
import Chart from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../store';
import { changeNum } from './common/function';

function Dashboard() {
    let dark = useSelector((state) => { return state.Darkmode.value });
    let SelectType = useSelector((state) => { return state.SelectType.value });
    let ex_name = useSelector((state) => { return state.ex_name.value });
    
    const darkname = dark ? 'dark' : "";

    let Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [withdrawbtn, setWithdrawbtn] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());

    const [typebtn, setTypebtn] = useState(false)
    const [selecttype, setSelectType] = useState("Binance")
    const [yearbtn, setYearbtn] = useState(false)
    const [selectyear, setSelectYear] = useState("2024")

    const [page, setPage] = useState(1)
    const [page1, setPage1] = useState(1)
    const [totalpage1, setTotalpage1] = useState(1)

    const [recentlyData, setRecentlyData] = useState([])
    const [withdrawData, setWithdrawData] = useState([])
    const [withdrawAlert, setWithdrawAlert] = useState([0, 0, 0])

    const [bn_volume, setBn_volume] = useState([0, 0])
    const [okx_volume, setOKX_volume] = useState([0, 0])
    const [by_volume, setBy_volume] = useState([0, 0])

    const [bn_year, setBn_year] = useState([])
    const [okx_year, setOKX_year] = useState([])
    const [bybit_year, setBybit_year] = useState([])

    const [series, setSeries] = useState([0, 0, 0])
    const [dataArr1, setDataArr1] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [dataArr2, setDataArr2] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [dataArr3, setDataArr3] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [dataArr4, setDataArr4] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [total, setTotal] = useState([0, 0, 0, 0])

    const [max, setMax] = useState(3000)

    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            RecentlyData(token)
        }
    }, [page])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            // WithdrawalData(token)
        }
    }, [page1])

    const RecentlyData = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/dashboard/user/record', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setRecentlyData(tmp)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const WithdrawalData = async (token) => {
        await axios.get(`https://api.coinrf.io//tmp/admin/withdrawal-list`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            console.log(response.data)
            // if (tmp) {
            //     setWithdrawData(tmp.withdrawals)
            //     setPage1(tmp.page.page_num)
            //     setTotalpage1(tmp.page.total_pages)
            //     setWithdrawAlert(tmp.alert_count)
            // }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            MonthData(token)
        }
    }, [])

    const MonthData = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/dashboard/year/volume', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setBn_year(tmp.bn)
                setOKX_year(tmp.okx)
                setBybit_year(tmp.bybit)
            }
        }).catch(error => {
            console.error(error);
        });

    }

    useEffect(() => {
        let tmpData = selecttype === "Binance" ? bn_year : selecttype === "OKX" ? okx_year : bybit_year
        let data1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let data2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let data3 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let data4 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let total1 = 0
        let total2 = 0
        let total3 = 0
        let total4 = 0
        let v_max = 0

        if (tmpData) {
            for (let i = 0; i < 12; i++) {
                let check = true
                for (let j = 0; j < tmpData.length; j++) {
                    let arr = tmpData[j].date.split('-')
                    if (Number(arr[0]) === Number(selectyear) && Number(arr[1]) === i + 1) {
                        data1[i] = Number(tmpData[j].volume).toFixed(4)
                        data2[i] = Number(tmpData[j].commission).toFixed(4)
                        data3[i] = Number(tmpData[j].reward).toFixed(4)
                        data4[i] = Number((tmpData[j].commission - tmpData[j].reward)).toFixed(4)

                        total1 += Number(tmpData[j].volume)
                        total2 += Number(tmpData[j].commission)
                        total3 += Number(tmpData[j].reward)
                        total4 += Number(tmpData[j].commission) - Number(tmpData[j].reward)

                        v_max = Math.max(v_max, Number(tmpData[j].commission))
                        check = false
                    }
                }
                if (check) {
                    data1[i] = Number(0).toFixed(4)
                    data2[i] = Number(0).toFixed(4)
                    data3[i] = Number(0).toFixed(4)
                }
            }
            setDataArr1(data1)
            setDataArr2(data2)
            setDataArr3(data3)
            setDataArr4(data4)

            if (v_max != 0) {
                let max1 = 0
                for (let k = 500; k <= v_max; k += 500) {
                    max1 = k
                }
                setMax(max1 + 500)
            }

            setTotal([total1.toFixed(4), total2.toFixed(4), total3.toFixed(4), total4.toFixed(4)])
        }
    }, [selecttype, selectyear, bn_year])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DayData(token)
        }
    }, [selectedDate, selectedDate1])

    const DayData = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/dashboard/total/record', {
            params: {
                start: selectedDate.getTime(),
                end: selectedDate1.getTime()
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setBn_volume([tmp.bn.volume, tmp.bn.reward])
                setOKX_volume([tmp.okx.volume, tmp.okx.reward])
                setBy_volume([tmp.bybit.volume, tmp.bybit.reward])
                setSeries([tmp.bn.volume > 0 ? tmp.bn.volume : 0, tmp.okx.volume > 0 ? tmp.okx.volume : 0, tmp.bybit.volume > 0 ? tmp.bybit.volume : 0])
            }
        }).catch(error => {
            console.error(error);
        });

    }

    const options = {
        chart: {
            width: 380,
            type: 'donut',
        },
        labels: ['Binance', 'OKX', 'Bybit'],
        colors: ['#fcd535', '#777777', '#f4a203'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };
    const options1 = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 1, 1]
        },
        colors: ['#ff4560', '#008FFB', '#00E396', '#FEB019'],
        xaxis: {
            categories: Month,
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#636363'
                },
                labels: {
                    style: {
                        colors: '#636363',
                    },
                    formatter: function (value) {
                        return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                title: {
                    text: "Volume",
                    style: {
                        color: '#636363',
                    }
                },
                tooltip: {
                    enabled: true
                },
                tickAmount: 4
            },
            {
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#636363'
                },
                labels: {
                    style: {
                        colors: '#636363',
                    },
                    formatter: function (value) {
                        return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                title: {
                    text: "Commission, Reward, Revenue",
                    style: {
                        color: '#636363',
                    }
                },
                tooltip: {
                    enabled: true
                },
                max: max,
                tickAmount: 4
            },
            {
                seriesName: 'Reward',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#00E396'
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    }
                },
                title: {
                    text: "Reward",
                    style: {
                        color: '#00E396',
                    }
                },
                show: false,
                max: max
            },
            {
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019'
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: "Revenue",
                    style: {
                        color: '#FEB019',
                    }
                },
                show: false,
                max: max
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
    }
    const options2 = {
        chart: {
            width: 380,
            type: 'donut',
        },
        labels: ['None'],
        colors: ['#777777'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    const series1 = [{
        name: 'Volume',
        type: 'line',
        data: dataArr1
    }, {
        name: 'Commission',
        type: 'column',
        data: dataArr2
    }, {
        name: 'Reward',
        type: 'column',
        data: dataArr3
    }, {
        name: 'Revenue',
        type: 'column',
        data: dataArr4
    }];

    const getMonthName = (index) => {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return months[index];
    }

    const arr2 = Array.from({ length: 12 }, (_, index) => {
        const monthName = getMonthName(index); // 월 이름 가져오기 (사용자 정의 함수)

        return {
            month: monthName,
            amount: dataArr1[index],
            commission: dataArr2[index],
            reward: dataArr3[index]
        };
    });


    let slice_data = recentlyData.slice((page - 1) * 5, (page) * 5)

    return (
        <div className='main'>
            <Sidebar page={15} />
            <div className={`main-box ${darkname}`}>
                <div className='dashboard-box'>
                    <div className='dashboard-1'>
                        <div className={`dashboard-3 ${darkname}`}>
                            <div className="dashboard-3-1">
                                <div className={`dashboard-3-1-1 ${darkname}`}>Accrued Fees</div>
                                <div className={`modal-3-1-left-1 dashboard-3-1-2 flex ${darkname}`}>
                                    <DatePicker
                                        dateFormat='yyyy-MM-dd' // 날짜 형태
                                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                        minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                                        maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                    />
                                    <div>~</div>
                                    <DatePicker
                                        dateFormat='yyyy-MM-dd' // 날짜 형태
                                        shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                                        minDate={selectedDate} // minDate 이전 날짜 선택 불가
                                        maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                                        selected={selectedDate1}
                                        onChange={(date) => setSelectedDate1(date)}
                                    />
                                </div>
                            </div>
                            <div className={`dashboard-3-2 ${darkname}`}>
                                <div style={{ minWidth: "380px", minHeight: "250px" }}>
                                    {
                                        series && series[0] === 0 && series[1] === 0 && series[2] === 0
                                            ?
                                            <Chart options={options2} series={[1]} type='donut' width={360} />
                                            :
                                            <Chart options={options} series={series} type='donut' width={380} />
                                    }
                                </div>
                                <div className='dashboard-3-3'>
                                    <div className='dashboard-3-3-1'>
                                        <div className={`dashboard-3-3-2 ${darkname}`}>
                                            <img src='/img/binance.png' className="dashboard-3-3-3" height={25} />
                                            <div className='dashboard-3-3-4'>{bn_volume ? changeNum(Number(bn_volume[0]).toFixed(3)) : 0} <span>USDT</span></div>
                                        </div>
                                        <div className={`dashboard-3-3-2 ${darkname}`}>
                                            <div className={`dashboard-3-3-3 ${darkname}`}>BINANCE Reward</div>
                                            <div className='dashboard-3-3-4'>{bn_volume ? changeNum(Number(bn_volume[1]).toFixed(3)) : 0} <span>USDT</span></div>
                                        </div>
                                    </div>
                                    <div className='dashboard-3-3-1'>
                                        <div className={`dashboard-3-3-2 ${darkname}`}>
                                            {
                                                dark
                                                    ?
                                                    <img src='/img/okx_dark.png' className="dashboard-3-3-3" height={20} />
                                                    :
                                                    <img src='/img/okx.png' className="dashboard-3-3-3" height={20} />
                                            }
                                            <div className='dashboard-3-3-4'>{okx_volume ? changeNum(Number(okx_volume[0]).toFixed(3)) : 0} <span>USDT</span></div>
                                        </div>
                                        <div className={`dashboard-3-3-2 ${darkname}`}>
                                            <div className={`dashboard-3-3-3 ${darkname}`}>OKX Reward</div>
                                            <div className='dashboard-3-3-4'>{okx_volume ? changeNum(Number(okx_volume[1]).toFixed(3)) : 0} <span>USDT</span></div>
                                        </div>
                                    </div>
                                    <div className='dashboard-3-3-1'>
                                        <div className={`dashboard-3-3-2 ${darkname}`}>
                                            {
                                                dark
                                                    ?
                                                    <img src='/img/bybit_dark.png' className="dashboard-3-3-3" height={21} />
                                                    :
                                                    <img src='/img/bybit.png' className="dashboard-3-3-3" height={21} />
                                            }
                                            <div className='dashboard-3-3-4'>{by_volume ? changeNum(Number(by_volume[0]).toFixed(3)) : 0} <span>USDT</span></div>
                                        </div>
                                        <div className={`dashboard-3-3-2 ${darkname}`}>
                                            <div className={`dashboard-3-3-3 ${darkname}`}>BYBIT Reward</div>
                                            <div className='dashboard-3-3-4'>{by_volume ? changeNum(Number(by_volume[1]).toFixed(3)) : 0} <span>USDT</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`dashboard-4 ${darkname}`}>
                            <div className="dashboard-4-1">
                                <div className={`dashboard-3-1-1 ${darkname}`}>Withdraw Alert</div>
                                <div className='dashboard-4-1-1'>
                                    <div className={`dashboard-4-1-1-tit ${darkname}`}>Binance <span>({withdrawAlert?.binance ?? 0})</span></div>
                                    <div className={`dashboard-4-1-1-tit ${darkname}`}>OKX <span>({withdrawAlert?.okx ?? 0})</span></div>
                                    <div className={`dashboard-4-1-1-tit ${darkname}`}>Bybit <span>({0})</span></div>
                                </div>
                                <div className='dashboard-4-2'>
                                    <div className={withdrawbtn === 0 ? `dashboard-3-1-2 on ${darkname}` : `dashboard-3-1-2 ${darkname}`} onClick={() => setWithdrawbtn(0)}>Binance</div>
                                    <div className={withdrawbtn === 1 ? `dashboard-3-1-2 on ${darkname}` : `dashboard-3-1-2 ${darkname}`} onClick={() => setWithdrawbtn(1)}>OKX</div>
                                    <div className={withdrawbtn === 2 ? `dashboard-3-1-2 on ${darkname}` : `dashboard-3-1-2 ${darkname}`} onClick={() => setWithdrawbtn(2)}>Bybit</div>
                                    {/* <div className={withdrawbtn === 2 ? `dashboard-3-1-2 on ${darkname}` : `dashboard-3-1-2 ${darkname}`} onClick={() => alert("Coming Soon")}>Bybit</div> */}
                                </div>
                            </div>
                            <div className='dashboard-4-3'>
                                <div className={`table-head-dash ${darkname}`}>
                                    <div>Time</div>
                                    <div>{ex_name} ID</div>
                                    <div>Asset</div>
                                    <div>Amount</div>
                                    <div></div>
                                </div>
                                <div className='table-body' style={{ minHeight: "200px", paddingBottom: "0" }}>

                                    {
                                        withdrawData && withdrawData.length === 0
                                            ?
                                            <div className='data-nofound'>
                                                <img src='/img/img_nofound.png'></img>
                                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                <p>NO RECORDS FOUND.</p>
                                            </div>
                                            :
                                            <>
                                                {
                                                    withdrawData.map(function (a, i) {
                                                        return (
                                                            <DashboardWithdraw key={i} item={a} darkname={darkname} dark={dark} type={0} />
                                                        )
                                                    })
                                                }
                                                <Page page={page1} totalpage={totalpage1} setNewpage={setPage1} btn={withdrawbtn} />
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`dashboard-5 ${darkname}`}>
                            <div className="dashboard-4-1">
                                <div className={`dashboard-3-1-1 ${darkname}`}>Recently Joined Users</div>
                            </div>
                            <div className='dashboard-4-3'>
                                <div className={`table-head-dash ${darkname}`}>
                                    <div style={{ width: "50%" }}>{ex_name} ID</div>
                                    <div>Email</div>
                                    <div style={{ width: "50%" }}>Mobile</div>
                                    <div style={{ width: "50%" }}>Volume</div>
                                    <div style={{ width: "70%" }}>Regist Date</div>
                                </div>
                                <div className='table-body' style={{ minHeight: "200px", paddingBottom: "0" }}>
                                    {
                                        slice_data && slice_data.length === 0
                                            ?
                                            <div className='data-nofound'>
                                                <img src='/img/img_nofound.png'></img>
                                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                <p>NO RECORDS FOUND.</p>
                                            </div>
                                            :
                                            <>
                                                {
                                                    slice_data.map(function (a, i) {
                                                        return (
                                                            <DashboardRecentlyUser key={i} item={a} darkname={darkname} dark={dark} />
                                                        )
                                                    })
                                                }
                                                <NewPage initialData={recentlyData} page={page} setPage={setPage} maxFirstPage={5} />
                                            </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        SelectType == 0
                            ?
                            <div className={`dashboard-2 ${darkname}`}>
                                <div className="dashboard-4-1">
                                    <div className={`dashboard-3-1-1 ${darkname}`}>Transaction Amount & Average</div>
                                    <div className='dashboard-4-2'>
                                        <div className={`dashboard-3-1-2 ${darkname}`} onClick={() => { setTypebtn(e => !e) }} style={{ width: "70px" }}>
                                            {selecttype}
                                            {
                                                typebtn
                                                    ?
                                                    <FontAwesomeIcon icon={faSortUp} style={{ paddingLeft: "10px" }} />
                                                    :
                                                    <FontAwesomeIcon icon={faSortDown} style={{ paddingLeft: "10px" }} />
                                            }
                                            {
                                                typebtn
                                                    ?
                                                    <>
                                                        <div className='modal' onClick={() => setTypebtn(true)}></div>
                                                        <div className={`dashboard-1-select-box ${darkname}`}>
                                                            <div onClick={() => { setSelectType("Binance"); setTypebtn(true) }}>Binance</div>
                                                            <div onClick={() => { setSelectType("OKX"); setTypebtn(true) }}>OKX</div>
                                                            <div onClick={() => { setSelectType("Bybit"); setTypebtn(true) }}>Bybit</div>
                                                        </div>
                                                    </>
                                                    :
                                                    ""
                                            }
                                        </div>
                                        <div className={`dashboard-3-1-2 ${darkname}`} onClick={() => { setYearbtn(e => !e) }}>
                                            {selectyear}
                                            {
                                                yearbtn
                                                    ?
                                                    <FontAwesomeIcon icon={faSortUp} style={{ paddingLeft: "10px" }} />
                                                    :
                                                    <FontAwesomeIcon icon={faSortDown} style={{ paddingLeft: "10px" }} />
                                            }
                                            {
                                                yearbtn
                                                    ?
                                                    <>
                                                        <div className='modal' onClick={() => setYearbtn(true)}></div>
                                                        <div className={`dashboard-2-select-box ${darkname}`}>
                                                            <div onClick={() => { setSelectYear("2024"); setYearbtn(true) }}>2024</div>
                                                            <div onClick={() => { setSelectYear("2023"); setYearbtn(true) }}>2023</div>
                                                        </div>
                                                    </>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='dashboard-2-1'>
                                    <Chart options={options1} series={series1} type='line' minWidth={715} height={380} />
                                </div>
                                <div className='dashboard-2-2'>
                                    <div className={`dashboard-2-2-1 ${darkname}`}>
                                        <div className={`dashboard-2-2-1-tit ${darkname}`} style={{ textAlign: "left" }}>BIN total</div>
                                        <div style={{ textAlign: "right" }}>{changeNum(Number(total[0]))} <span>USDT</span></div>
                                    </div>
                                    <div className={`dashboard-2-2-1 ${darkname}`}>
                                        <div className={`dashboard-2-2-1-tit ${darkname}`} style={{ textAlign: "left" }}>Commission total</div>
                                        <div style={{ textAlign: "right" }}>{changeNum(Number(total[1]))} <span>USDT</span></div>
                                    </div>
                                    <div className={`dashboard-2-2-1 ${darkname}`}>
                                        <div className={`dashboard-2-2-1-tit ${darkname}`} style={{ textAlign: "left" }}>Reward total</div>
                                        <div style={{ textAlign: "right" }}>{changeNum(Number(total[2]))} <span>USDT</span></div>
                                    </div>
                                    <div className={`dashboard-2-2-1 ${darkname}`}>
                                        <div className={`dashboard-2-2-1-tit ${darkname}`} style={{ textAlign: "left" }}>Revenue total</div>
                                        <div style={{ textAlign: "right" }}>{changeNum(Number(total[3]))} <span>USDT</span></div>
                                    </div>
                                </div>
                                <div className='dashboard-2-3'>
                                    <div className={`table-head-dash ${darkname}`}>
                                        <div>Month</div>
                                        <div>Amount</div>
                                        <div>Commission</div>
                                        <div>Reward</div>
                                        <div>Revenue</div>
                                    </div>
                                    <div className='table-body' style={{ paddingBottom: "0" }}>
                                        {
                                            arr2.map(function (a, i) {
                                                return (
                                                    <DashboardTransaction key={i} item={a} darkname={darkname} dark={dark} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
            </div>
        </div>
    )
}

function DashboardWithdraw({ item, darkname, dark, type }) {
    const [btn1, setBtn1] = useState(false)
    const date = new Date(item.date);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    return (
        <>
            <div className={`table-body-tr-dash ${darkname}`}>
                <div>{formattedDate}</div>
                <div>{item.uid}</div>
                <div>{item.asset}</div>
                <div>{Number(item.amount)}</div>
                <div>
                    <div className={`withdraw-btn ${darkname}`} onClick={() => setBtn1(true)}>Withdraw</div>
                </div>
            </div>
            {
                btn1
                    ?
                    <ModalWithdraw type={type} setBtn1={setBtn1} darkname={darkname} item={item} formattedDate={formattedDate} />
                    :
                    ""
            }
        </>
    )
}

function DashboardRecentlyUser({ item, darkname }) {
    let tmpdate = item.regist_date.split("T")
    let tmpdate1 = tmpdate[1].split(".000Z")
    return (
        <div className={`table-body-tr-dash ${darkname}`}>
            <div style={{ width: "50%" }}>{item.uid}</div>
            <div>{item.email ? item.email : "-"}</div>
            <div style={{ width: "50%" }}>{item.mobile ? item.mobile : "-"}</div>
            <div className='right' style={{ width: "50%" }}>{changeNum(Number(item.bn_volume + item.okx_volume + item.bybit_volume).toFixed(2))}</div>
            <div style={{ width: "70%" }}>{tmpdate[0]} {tmpdate1[0]}</div>
        </div>
    )
}

function DashboardTransaction({ item, darkname, dark }) {
    return (
        <div className={`table-body-tr-dash ${darkname}`}>
            <div className='left'>{item.month}</div>
            <div className='right'>{changeNum(Number(item.amount).toFixed(4))}</div>
            <div className='right'>{changeNum(Number(item.commission).toFixed(4))}</div>
            <div className='right'>{changeNum(Number(item.reward).toFixed(4))}</div>
            <div className='right'>{changeNum((Number(item.commission) - Number(item.reward)).toFixed(4))}</div>
        </div>
    )
}

function ModalWithdraw({ type, setBtn1, darkname, item, formattedDate }) {
    let ex_name = useSelector((state) => { return state.ex_name.value });
    const [depositData, setDepositData] = useState([])

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setBtn1(false);
        }
    };
    document.addEventListener('keydown', handleEsc);

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositData(token)
        }
    }, [])

    const DepositData = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/${type === 0 ? "bn" : type === 1 ? "okx" : "bybit"}/user/deposit/history`, {
            params: {
                start: (new Date()).getTime() - 2592000000,
                end: new Date().getTime(),
                uid: item.exuid
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.code === 1000) {
                setDepositData(tmp.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });

    }

    return (
        <div className='modal'>
            <div className='modal-box'>
                <div className='modal-1'>
                    <div className='modal-1-tit-1'>
                        <div>Withdraw</div>
                        <div className={`modal-1-tit-box ${darkname}`}>{ex_name} ID : {item.uid}</div>
                        <div className={`modal-1-tit-box ${darkname}`}>Status : {item.status === 0 ? "Pending" : "Complete"}</div>
                        <div className={`modal-1-tit-box ${darkname}`}>Time : {formattedDate}</div>
                        <FontAwesomeIcon icon={faXmark} className='modal-x' onClick={() => setBtn1(false)} />
                    </div>
                    <div className='modal-1-1'>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-1-tit'>Asset</div>
                            <div className='modal-1-1-1-txt'>{item.asset}</div>
                            <div className='modal-1-1-1-tit'>Amount</div>
                            <div className='modal-1-1-1-txt'>{Number(item.amount)}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-2-tit'>{type === 0 ? "Binance UID" : type === 1 ? "OKX UID" : "Bybit UID"}</div>
                            <div className='modal-1-1-2-txt'>{item.exuid}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-2-tit'>TXID</div>
                            <div className='modal-1-1-2-txt'>{item.txid}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-2-tit'>wallet</div>
                            <div className='modal-1-1-2-txt'>{item.wallet}</div>
                        </div>
                        <div className='modal-1-1-1'>
                            <div className='modal-1-1-2-tit'>IP</div>
                            <div className='modal-1-1-2-txt'>{item.ip}</div>
                        </div>
                    </div>
                    {
                        item.status === 0
                            ?
                            <div className='modal-1-3'>
                                <div className='modal-1-3-1'>Confirm</div>
                                <div className='modal-1-3-1'>Reject</div>
                            </div>
                            :
                            ""
                    }
                    {/* <div className={`hr ${darkname}`}></div> */}
                    {
                        depositData.length === 0
                            ?
                            ""
                            :
                            <div className='modal-1-4'>
                                <div className='modal-1-tit-1' style={{ marginBottom: "0px" }}>
                                    <div>Deposit History</div>
                                </div>
                                <div className={`table-head ${darkname}`}>
                                    <div>Date</div>
                                    <div>TX</div>
                                    <div>Amount</div>
                                    <div>Asset</div>
                                    <div>Network</div>
                                    <div>Wallet</div>
                                </div>
                                <div className='table-body' style={{ marginBottom: "20px" }}>
                                    {
                                        depositData.map(function (a, i) {
                                            if (i <= 3) {
                                                return (
                                                    <DepositItem key={i} item={a} darkname={darkname} />
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className='modal-1-3'>
                                    <div className='modal-1-3-1' onClick={() => { window.open(`/member`) }}>See More</div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

function DepositItem({ item, darkname }) {
    let tmpdate = item.deposit_time
    let formattedDate = `${tmpdate[0]}.${padZero(tmpdate[1])}.${padZero(tmpdate[2])} ${padZero(tmpdate[3])}:${padZero(tmpdate[4])}:${tmpdate[5] ? padZero(tmpdate[5]) : "00"}`;

    return (
        <>
            <div className={`table-body-tr ${darkname}`}>
                <div>{formattedDate}</div>
                <div>{item.txid}</div>
                <div>{item.amount}</div>
                <div>{item.asset}</div>
                <div>{item.network}</div>
                <div>{item.wallet}</div>
            </div>
        </>
    )
}


function changeTime(date) {
    const utcDate = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
    return utcDate
}


function padZero(value) {
    return value < 10 ? `0${value}` : value;
}

export default Dashboard;