import '../css/main.css'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircleXmark, faSortDown, faSortUp, faWindowMinimize, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from 'react';
import Sidebar from '../../Nav/sidebar';
import NewPage from '../NewPage'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from '../../store';
import { SyncLoader } from "react-spinners";
import { removeZeros, changeNum } from '../common/function'
import base64 from "base-64";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Admin_Settlement() {
    let login = useSelector((state) => { return state.login.value; });
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, [])

    useEffect(() => {
        let objString = localStorage.getItem('token')
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = base64.decode(payload);
            let sub = JSON.parse(dec).authority
            if (sub != "SUPER") {
                navigate('/')
            }
        }
    }, [login])

    return (
        <div className='main'>
            <Sidebar page={20} />
            <Day_Settlement />
        </div>
    )
}

function Day_Settlement() {
    const [page, setPage] = useState(1)
    const [dateBtn, setDateBtn] = useState(0)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(new Date());

    const [settlement, setSettlement] = useState([])
    const [settlement_current, setSettlement_current] = useState([])
    const [settlement_pre, setSettlement_pre] = useState([])
    const [spinner, setSpinner] = useState(false)

    const [total, setTotal] = useState([0, 0, 0, 0])
    const [pre_total, setPre_Total] = useState([0, 0, 0, 0])

    let dark = useSelector((state) => { return state.Darkmode.value });

    const darkname = dark ? 'dark' : "";

    useEffect(() => {
        onSpinner()
        Settlement()
    }, [selectedDate, selectedDate1])

    const Settlement = async () => {
        await axios.get(process.env.REACT_APP_BROKER_API + '/api/v1/broker/admin/get/settlement/list', {
            withCredentials: true,
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                setSettlement(tmp.tradeListResult)
            }
        }).catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        let t = 0
        let bn = 0
        let o = 0
        let by = 0
        let arr = []

        if (settlement && settlement.length > 0) {
            for (let i = 0; i < settlement.length; i++) {
                if (Math.floor(changeTime(selectedDate) / 1000) * 1000 <= new Date(settlement[i].fill_date).getTime() && new Date(settlement[i].fill_date).getTime() <= Math.floor(changeTime(selectedDate1) / 1000) * 1000 + 86399999) {
                    arr.push(settlement[i])
                }
            }

            for (let i = 0; i < arr.length; i++) {
                let tmp = arr[i]
                t += Number(tmp.binance_income) + Number(tmp.okx_income) + Number(tmp.bybit_income)
                bn += Number(tmp.binance_income)
                o += Number(tmp.okx_income)
                by += Number(tmp.bybit_income)
            }
            setSettlement_current(arr)
            setTotal([t, bn, o, by])
        }
    }, [settlement])

    useEffect(() => {
        let t = 0
        let bn = 0
        let o = 0
        let by = 0
        let arr = []

        if (settlement && settlement.length > 0) {
            for (let i = 0; i < settlement.length; i++) {
                if (Math.floor((changeTime(selectedDate) - (changeTime(selectedDate1) - changeTime(selectedDate)) - 86400000) / 1000) * 1000
                    <= new Date(settlement[i].fill_date).getTime() && new Date(settlement[i].fill_date).getTime() <= Math.floor(changeTime(selectedDate) / 1000) * 1000 - 1) {
                    arr.push(settlement[i])
                }
            }

            for (let i = 0; i < arr.length; i++) {
                let tmp = arr[i]
                t += Number(tmp.binance_income) + Number(tmp.okx_income) + Number(tmp.bybit_income)
                bn += Number(tmp.binance_income)
                o += Number(tmp.okx_income)
                by += Number(tmp.bybit_income)
            }
            setSettlement_pre(arr)
            setPre_Total([t, bn, o, by])
        }
    }, [settlement])

    let slice_data = settlement_current.slice((page - 1) * 15, (page) * 15)

    const onSpinner = () => {
        setSpinner(true)
        setTimeout(() => {
            setSpinner(false)
        }, 500)
    }

    const excelData = () => {
        let arr = []
        if (settlement_current) {
            for (let i = 0; i < settlement_current.length; i++) {
                let t = settlement_current[i]
                arr.push({
                    "레퍼러": t.broker,
                    "배율": t?.rebate_rate ?? "-",
                    "Bn_누적정산액": t?.binance_volume ?? "-",
                    "Bn_정산액": t?.binance_income ?? "-",
                    "Bn_ID": t?.binance_tx_id ?? "-",
                    "O_누적정산액": t?.okx_volume ?? "-",
                    "O_정산액": t?.okx_income ?? "-",
                    "O_ID": t?.okx_tx_id ?? "-",
                    "By_누적정산액": t?.bybit_volume ?? "-",
                    "By_정산액": t?.bybit_income ?? "-",
                    "By_ID": t?.bybit_tx_id ?? "-",
                })
            }
        }

        Excel(arr)
    }

    return (
        <div className={`main-box ${darkname}`}>
            <div className={`withdraw-1 ${darkname}`}>
                <div style={{ fontWeight: "bold" }}>Settlement History</div>
                <div className={`modal-3-1-left ${darkname}`}>
                    <div className={dateBtn === 0 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(0)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => { setSelectedDate(date); setSelectedDate1(date) }}
                        />
                    </div>
                    <div className={dateBtn === 1 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(1);
                        setSelectedDate(new Date((new Date()).getTime())); setSelectedDate1(new Date())
                    }}>Today</div>
                    <div className={dateBtn === 2 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(2);
                        setSelectedDate(new Date(new Date().getTime() - 604800000)); setSelectedDate1(new Date())
                    }}>7 Days</div>
                    <div className={dateBtn === 3 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(3);
                        setSelectedDate(new Date(new Date().getTime() - 2592000000)); setSelectedDate1(new Date())
                    }}>30 Days</div>
                    <div className={dateBtn === 4 ? "modal-3-1-left-1 on" : "modal-3-1-left-1"} onClick={() => {
                        setDateBtn(4);
                        setSelectedDate(new Date(new Date().getTime() - 7776000000)); setSelectedDate1(new Date())
                    }}>90 Days</div>
                    <div className={dateBtn === 5 ? "modal-3-1-left-1 on flex" : "modal-3-1-left-1 flex"} onClick={() => setDateBtn(5)}>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
                            maxDate={selectedDate1} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                        <div>~</div>
                        <DatePicker
                            dateFormat='yyyy-MM-dd' // 날짜 형태
                            shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                            minDate={selectedDate} // minDate 이전 날짜 선택 불가
                            maxDate={new Date()} // maxDate 이후 날짜 선택 불가
                            selected={selectedDate1}
                            onChange={(date) => setSelectedDate1(date)}
                        />
                    </div>
                </div>
            </div>
            <div className={`reward-box ${darkname}`}>
                <div className={`reward-box-1 ${darkname}`}>
                    <div className='reward-box-1-tit'>Total Settlements (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[0]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[0] - pre_total[0] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[0] - pre_total[0] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[0] - pre_total[0]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className={`reward-box-1 ${darkname}`}>
                    <div className='reward-box-1-tit'>Binance (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[1]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[1] - pre_total[1] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[1] - pre_total[1] >= 0 ? faCaretUp : faCaretDown} style={{ paddingDown: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[1] - pre_total[1]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className={`reward-box-1 ${darkname}`}>
                    <div className='reward-box-1-tit'>OKX (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[2]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[2] - pre_total[2] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[2] - pre_total[2] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[2] - pre_total[2]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
                <div className={`reward-box-1 ${darkname}`}>
                    <div className='reward-box-1-tit'>Bybit (USDT)</div>
                    <div className='reward-box-1-txt'>
                        <div className='reward-box-1-txt-1'>{changeNum((total[3]).toFixed(4))}</div>
                        <div className={`reward-box-1-txt-2 ${total[3] - pre_total[3] >= 0 ? "green" : "red"}`}>
                            <FontAwesomeIcon icon={total[3] - pre_total[3] >= 0 ? faCaretUp : faCaretDown} style={{ paddingTop: "5px", paddingRight: "5px" }} /> <span>{changeNum(Math.abs(total[3] - pre_total[3]).toFixed(4))}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tradingType-1'>
                <div className={`tradingType-1-2 ${darkname}`} onClick={() => excelData()}>Excel download</div>
            </div>
            <div className='tradingType-2'>
                <div className={`table-head ${darkname}`}>
                    <div>Referral</div>
                    <div>Rate</div>
                    <div>Binance<br /> Total Settlements</div>
                    <div>Binance Settlements</div>
                    <div>Binance ID</div>
                    <div>OKX<br /> Total Settlements</div>
                    <div>OKX Settlements</div>
                    <div>OKX ID</div>
                    <div>Bybit<br /> Total Settlements</div>
                    <div>Bybit Settlements</div>
                    <div>Bybit ID</div>
                </div>
                <div className='table-body' style={{ minHeight: "600px" }}>
                    {
                        spinner
                            ?
                            <div className='spinner-modal'>
                                <SyncLoader color="#F5AC1D" />
                            </div>
                            :
                            slice_data && slice_data.length === 0
                                ?
                                <div className='data-nofound'>
                                    <img src='/img/img_nofound.png'></img>
                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                    <p>NO RECORDS FOUND.</p>
                                </div>
                                :
                                <>
                                    {
                                        slice_data.map(function (a, i) {
                                            return (
                                                <SettlementItem key={i} item={a} darkname={darkname} />
                                            )
                                        })
                                    }
                                    <NewPage initialData={settlement_current} page={page} setPage={setPage} maxFirstPage={15} />
                                </>

                    }
                </div>
            </div>
        </div>
    )
}

function SettlementItem({ item, darkname }) {
    return (
        <div className={`table-body-tr ${darkname}`}>
            <div className='td'>{item.broker}</div>
            <div className='td'>{item?.rebate_rate ?? '-'}</div>
            <div className='td right'>{item?.binance_volume ?? '-'}</div>
            <div className='td right'>{item?.binance_income ?? '-'}</div>
            <div className='td right'>{item?.binance_tx_id ?? '-'}</div>
            <div className='td right'>{item?.okx_volume ?? '-'}</div>
            <div className='td right'>{item?.okx_income ?? '-'}</div>
            <div className='td right'>{item?.okx_tx_id ?? '-'}</div>
            <div className='td right'>{item?.bybit_volume ?? '-'}</div>
            <div className='td right'>{item?.bybit_income ?? '-'}</div>
            <div className='td right'>{item?.bybit_tx_id ?? '-'}</div>
        </div>
    )
}


const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `Total_Settlement`;

    const ws = XLSX.utils.aoa_to_sheet([
        ["Referral", "Rate", "Binance Total Settlements", "Binance Settlements", "Binance ID", "OKX Total Settlements", "OKX Settlements", "OKX ID", "Bybit Total Settlements", "Bybit Settlements", "Bybit ID"]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.레퍼러 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.배율 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Bn_누적정산액 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.Bn_정산액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.Bn_ID },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.O_누적정산액 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.O_정산액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.O_ID },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.By_누적정산액 },
                    { t: 'n', s: { alignment: { horizontal: 'center' } }, v: data.By_정산액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.By_ID }
                ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 },
            { wpx: 150 }
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};


function changeTime(d) {
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    return d.getTime()
}

export default Admin_Settlement;